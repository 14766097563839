import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { IoIosCall, IoIosMail } from "react-icons/io";

import { Helmet } from "react-helmet";

import Image from "../../components/image";
import { Container, Wrapper, Row, Box } from "../../components/util";
import { VideoCard } from "../../components/site";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const PartnerCard = styled(Link)`
  display: block;
  border-radius: ${(props) => props.theme.styles.borderRadius};
  box-shadow: ${(props) => props.theme.styles.shadow};
  background-color: ${(props) => props.theme.colours.white};
  color: ${(props) => props.theme.colours.dark};
  text-decoration: none;

  &:hover {
    .resource-content {
      h2,
      h4 {
        color: ${(props) => props.theme.colours.Secondary};
      }
    }
  }

  img {
    border-radius: 5px 5px 0 0;
  }

  .resource-content {
    padding: 35px;
  }
`;

const VideoRow = styled(Row)`
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: calc(100% + 30px);
  }
`;

const PartnerResources = (props) => (
  <Layout>
    <Seo
      title="Partner Resources | FlexiTime Partner"
      pathname={props.location.pathname}
    />
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Container>
      <Wrapper>
        <Box stackGap={70}>
          <h1 className="-textCenter">Partner Resources</h1>
          <Box stackGap={40}>
            <Row stackGap={40}>
              <Box size={50}>
                <PartnerCard to="/partner-resources/client-setup">
                  <Image
                    filename="Partner Resource Pic1.png"
                    alt="Partner Toolkit Pic"
                  />
                  <div className="resource-content">
                    <h2>Get Clients Set Up</h2>
                    <h4>Guides and processes to set up your clients</h4>
                  </div>
                </PartnerCard>
              </Box>
              <Box size={50}>
                <PartnerCard to="/partner-resources/marketing">
                  <Image
                    filename="Partner Resource Pic4.png"
                    alt="Partner Toolkit Pic"
                  />
                  <div className="resource-content">
                    <h2>Marketing & Templates</h2>
                    <h4>
                      Get marketing material like client flyers, logos & more
                    </h4>
                  </div>
                </PartnerCard>
              </Box>
            </Row>
          </Box>
          <VideoRow isWrap justify="flex-start">
            <VideoCard
              embed_link="https://player.vimeo.com/video/651822020"
              filter=""
              topic="Webinar"
              title="Essential Payroll Tips for the 2021 Holiday Season"
              description="Grace & Pete go through some useful tips to help you get payroll right this holiday season."
              watch_time="34:39"
            />
            <VideoCard
              embed_link="https://player.vimeo.com/video/649740158"
              filter=""
              topic="Webinar"
              title="Summer rostering with Droppah Partner Webinar"
              description="Grace and Pete dive into Droppah's powerful rostering feature and how best to utilise it this summer."
              watch_time="21:35"
            />
            <VideoCard
              embed_link="https://player.vimeo.com/video/621878894"
              filter=""
              topic="Webinar"
              title="Introducing Droppah Partner Webinar"
              description="Pete and Grace introduce our latest product, Droppah, rostering and time clock software for shift workers."
              watch_time="29:08"
            />
            <VideoCard
              embed_link="https://player.vimeo.com/video/552676299"
              filter=""
              topic="Webinar"
              title="FlexiTime Partner Program Webinar"
              description="In this webinar Pete, Jacinta & Jake explain the changes to the FlexiTime partner program and do a quick run through of the new partner app."
              watch_time="18:25"
            />
            <VideoCard
              embed_link="https://player.vimeo.com/video/549046485"
              filter=""
              topic="Video"
              title="Getting Started with the FlexiTime Partner App"
              description="Manage your client accounts and partner staff access levels with FlexiTime's new partner management app. Learn how to get started in this quick run through video."
              watch_time="3:34"
            />
          </VideoRow>
        </Box>
      </Wrapper>
    </Container>
    <Container bg="lightGrey">
      <Wrapper stackGap={80}>
        <Box stackGap={50}>
          <h2 className="-textCenter">Helpful links</h2>
          <Row justify="center" stackGap={20} alignCenter>
            <a
              className="link-floating"
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.payhero.co.nz/hc/en-us/sections/360000731875-Release-Notes"
            >
              PayHero Release Notes
            </a>
            <span>·</span>
            <a
              className="link-floating"
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.droppah.com/hc/en-us/sections/360000492275-Release-Notes"
            >
              Droppah Release Notes
            </a>
            <span>·</span>
            <a
              className="link-floating"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.payhero.co.nz/interactive-demo"
            >
              Try a PayHero Demo
            </a>
            <span>·</span>
            <a
              className="link-floating"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.droppah.com/interactive-demo"
            >
              Try a Droppah Demo
            </a>
            <span>·</span>
            <Link
              className="link-floating"
              to="/partner-resources/code-of-conduct"
            >
              Partner Code of Conduct
            </Link>
            <span>·</span>
            <a
              className="link-floating"
              href="https://calendly.com/flexitime-partners"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Support
            </a>
          </Row>
        </Box>
      </Wrapper>
    </Container>
    <Container className="-textCenter secondary">
      <Wrapper stackGap={80}>
        <h2>Need help? Get in touch</h2>
        <Row css={{ maxWidth: "900px" }} className="-center">
          <Box className="-center" size={50} stackGap={30}>
            <Image
              filename="PeteAshby_Circle.png"
              alt="Pete Ashby | FlexiTime Partner Contact"
              width={80}
              centerImage
            />
            <h4>
              <span className="-fontBold">Pete Ashby</span>
              <br />
              Head of Discovery
            </h4>
            <h4 className="-fontBold">
              <a
                href=" https://calendly.com/flexitime-partners/partner-manager"
                target="_blank"
                rel="noreferrer"
                className="link-floating -center"
              >
                <IoIosCall css={{ position: "relative", top: "3px" }} /> Book a
                Call
              </a>
            </h4>
            <h4 className="-fontBold">
              <a
                className="link-floating -center"
                href="mailto:pete@flexitime.works"
              >
                <IoIosMail
                  css={{ position: "relative", top: "3px", marginRight: "5px" }}
                />{" "}
                pete@flexitime.works
              </a>
            </h4>
          </Box>
        </Row>
      </Wrapper>
    </Container>
  </Layout>
);

export default PartnerResources;
